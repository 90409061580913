import { AuthConfig } from "./services/authService";
import { Injectable } from "injection-js";
import { NotificationLevel } from "./services/notificationLevel";

/**
 * Common configuration settings. These are populated using the environment variables of the process
 * running the build. This means they're fixed at runtime.
 */
@Injectable()
export class Environment implements IEnvironment {
  /** The name of the environment the app is running in. */
  public readonly envName: string;
  /** The URL where UIX is being served. */
  public readonly bankerUrl: string;
  /** The base URL where the API is being served. */
  public readonly apiBaseUrl: string;
  /** The base URL where the loan insights portal is being served. */
  public readonly lipBaseUrl: string;
  /** The API key used to publically authenticate with the loan insights portal. */
  public readonly apiKey: string;
  /** The base URL where the Security Host is being served. */
  public readonly loginBaseUrl: string;
  /** The license key required by Syncfusion (and which is leaked in the generated artifacts). */
  public readonly syncfusionLicenseKey: string;
  public readonly theme?: string;
  public readonly appInsightsKey?: string;
  /**
   * The Client ID of this application in Entra ID. Used to configure
   * [MSAL](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md).
   */
  public readonly clientId: string;
  /**
   * The authority URL to trust for authentication.
   */
  public readonly authority: string;
  /**
   * The scopes to request when getting access tokens.
   */
  public readonly scopes: string[];
  /** Notifications which have a level strictly less than this should not be displayed. */
  public readonly minNotificationLevel: NotificationLevel;
  public readonly showSideBar: boolean;

  constructor() {
    const varName = "REACT_APP_ENV_NAME";
    this.envName = Environment.readRequired(varName);
    let config: IEnvironment;
    switch (this.envName) {
      case EnvName.LOCAL: {
        config = localConfig();
        break;
      }
      case EnvName.DEV: {
        config = devConfig();
        break;
      }
      case EnvName.PROD: {
        config = prodConfig();
        break;
      }
      default: {
        throw new Error(
          `Unknown environment name '${this.envName}' from environment variable '${varName}'.`
        );
      }
    }

    this.bankerUrl = config.bankerUrl;
    this.apiBaseUrl = config.apiBaseUrl;
    this.lipBaseUrl = config.lipBaseUrl;
    this.apiKey = config.apiKey;
    this.loginBaseUrl = config.loginBaseUrl;
    this.syncfusionLicenseKey = config.syncfusionLicenseKey;
    this.theme = config.theme;
    this.appInsightsKey = config.appInsightsKey;
    this.clientId = config.clientId;
    this.authority = config.authority;
    this.scopes = config.scopes;
    this.minNotificationLevel = config.minNotificationLevel;
    this.showSideBar = config.showSideBar;
  }

  private static readRequired(name: string): string {
    const value = process.env[name];
    if (!value)
      throw new Error(
        `Required environment variable was not defined: '${name}'`
      );
    return value;
  }

  public authConfig(): AuthConfig {
    return {
      clientId: this.clientId,
      authority: this.authority,
      redirectUri: this.bankerUrl,
      scopes: this.scopes,
    };
  }
}

interface IEnvironment {
  bankerUrl: string;
  apiBaseUrl: string;
  lipBaseUrl: string;
  apiKey: string;
  loginBaseUrl: string;
  syncfusionLicenseKey: string;
  theme?: string;
  appInsightsKey?: string;
  clientId: string;
  authority: string;
  scopes: string[];
  minNotificationLevel: NotificationLevel;
  showSideBar: boolean;
}

function localConfig(): IEnvironment {
  return {
    syncfusionLicenseKey:
      "Mgo+DSMBaFt+QHFqVk5rXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlljT39Tc0RjXXtfdnM=;ORg4AjUWIQA/Gnt2VFhhQlJMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdkNiWXpfcHBRRWFb",
    apiBaseUrl: "https://localhost:44351",
    lipBaseUrl: "https://localhost:44384",
    apiKey: "fd1495ad-72b8-4e79-8f0f-399f19217b77",
    loginBaseUrl: "https://localhost:44356",
    bankerUrl: "http://localhost:3000/",
    clientId: "5e9e52ea-661a-4626-b508-8339ed0a5393",
    authority: "https://login.microsoftonline.com/organizations/v2.0/",
    scopes: ["openid", "email", "https://relativityx.com/relx-local/internal"],
    minNotificationLevel: NotificationLevel.Debug,
    showSideBar: false,
  };
}

function devConfig(): IEnvironment {
  const config = localConfig();
  config.apiBaseUrl = "https://api-dev.relativityx.com";
  config.lipBaseUrl = "https://lip-dev.relativityx.com";
  config.apiKey = "696135b8-355b-42b8-a907-777303a1149e";
  config.bankerUrl = "https://banker-dev.relativityx.com";
  config.loginBaseUrl = "https://login-dev.relativityx.com";
  config.clientId = "a4c34fe5-68bf-4461-97f5-37b0afb7bc88";
  config.scopes = [
    "openid",
    "email",
    "https://relativityx.com/relx-dev/internal",
  ];
  return config;
}

function prodConfig(): IEnvironment {
  const config = localConfig();
  config.apiBaseUrl = "https://api.relativityx.com";
  config.lipBaseUrl = "https://lip.relativityx.com";
  config.bankerUrl = "https://banker.relativityx.com";
  config.loginBaseUrl = "https://login.relativityx.com";
  config.clientId = "(needs to be configured)";
  config.scopes = ["openid", "email", "https://relativityx.com/relx/internal"];
  config.minNotificationLevel = NotificationLevel.Info;
  return config;
}

/** The names of the environments the app runs in. */
export enum EnvName {
  LOCAL = "LOCAL",
  DEV = "DEV",
  PROD = "PROD",
}
