import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { useContext } from "react";
import { SelectionContext } from "../../pages/BankerPage/SelectionContext";
import RefiTable, { RefiTableDisplaySettings } from "../RefiTable/RefiTable";
export interface CrossSellCardProps {
  title?: string;
  salesSettings: SalesSettings | undefined;
  displaySettings?: RefiTableDisplaySettings;
}

export const CrossSellCard: React.FC<CrossSellCardProps> = ({
  title,
  salesSettings,
  displaySettings,
}) => {
  const { selected } = useContext(SelectionContext);
  const refis = [
    ...(selected?.liabilities || []),
    ...(selected?.upsellOptions || []),
  ].filter(
    (option) => !selected?.isRecommended(option) && !selected?.isTopRank(option)
  );

  return (
    <div className="card w-full bg-white shadow-lg rounded-md overflow-x-auto">
      <div className="card-body">
        <h3>{title}</h3>

        <RefiTable
          refis={refis}
          requestedLoan={selected?.requestedLoan}
          salesSettings={salesSettings}
          selectable={true}
          displaySettings={displaySettings}
        />
      </div>
    </div>
  );
};

export default CrossSellCard;
